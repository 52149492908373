import React from "react";
import { FormattedMessage } from 'react-intl'
const NotFoundPage = () => (

  <div>

     <p>                  <FormattedMessage
                    id="notFound"/></p>
  </div>

);

export default NotFoundPage;
